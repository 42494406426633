import { React } from "react";
import { styled } from "@mui/material";
import Headshot from './Headshot.jpg';
import SW2 from './SW 2.jpg';
import './AboutMe.css'

const StyledMainContainer = styled("div")(({ theme }) => ({
 // display: "flex",
  //flexDirection: "row",
  //alignItems: "center",
  margin: "6rem 12rem",
  gap: "4rem",
  alignSelf: "center",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    margin: "2rem 2rem",
    width: "90%",
    gap: "2rem",
  },
}));



function About() {
  return (
    <div className="about-me-container">
    <div className="header">
      <h1>Sage Walker, Licensed Massage Therapist</h1>
      <p>License #7553</p>
      <img src={SW2} alt="Sage Walker" className="photo" />
    </div>

    <div className="content">
      <h2>Homeostasis: “the ability to maintain a relatively stable internal state that persists despite changes to the world outside”</h2>

      <p>
        I have struggled personally with emotional dysregulation my entire life. I was eventually introduced to somatic (body-focused) therapies as a way to help regulate my hyper-activated nervous system, and was surprised by their impact not only on my emotional well-being but on reducing my chronic muscle pain and digestive symptoms as well. This discovery changed the trajectory of my life and career - it inspired a journey of self-exploration and formal education into the healing arts and sciences of massage therapy.
      </p>

      <p>
        Homeostasis Massage & Wellness is the result of this ongoing personal and professional endeavor. I have always been fascinated by the human body, and I am especially interested in the nervous system and its role in how we experience pain, pleasure, and everything in between. I view massage, movement, breath, and temperature as tools that I can use to communicate with a person’s nervous system, helping them feel safe, grounded, and connected to their bodies; reducing pain, increasing pleasure, and achieving enough internal balance and stability to enable healing.
      </p>

      <p>
        Each of our bodies is constantly making adjustments, large and small, such as heart rate, blood pressure, and perspiration, to maintain equilibrium in a constantly changing environment. “Homeostasis” refers to that state of internal balance, which the body relies on to be healthy and functional. This concept inspires my massage practice. At Homeostasis Massage & Wellness, I work with the body to bring awareness, relief, and change, helping each client leave my table feeling more balanced and stable than when they arrived.
      </p>

      <p>
        My corny catchphrase is “bodywork for every body.” I love working with people who may not have considered massage as an option in the past, having been explicitly and/or implicitly told as much. As a transgender working-class person, I never felt that massage was accessible to me - and that is something I aim to change. I use transparency, communication, and education to help people feel safe and comfortable in my treatment room, and I offer a sliding scale in an effort to make my services more financially accessible.
      </p>

      <p>
        Questions about me or my practice? Please don’t hesitate to&nbsp;  
        <a href="https://forms.gle/sqCaA1PbaFrff8Av5">reach out</a> 
        &nbsp;- I would love to hear from you.
      </p>
    </div>

    <div className="footer">
      <img src={Headshot} alt="Headshot" className="photo" />
    </div>
  </div>

  );
}

export default About;
