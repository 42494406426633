import { React, useState, useEffect } from "react";
import './faq.css';
import {
  Grid,
  Pagination,
  useMediaQuery,
  useTheme,
  styled,
} from "@mui/material";
import Footer from "../footer/Footer";


const mainStyles ={
  background: '#f5f5dc',
}
//component styles
  const faqStyles = {
    fontFamily: 'Jost, sans-serif',
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    
  };

  const questionStyles = {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '8px',
    color: '#2db4d2',
  };

  const answerStyles = {
    fontSize: '18px',
    marginBottom: '20px',
    lineHeight: '1.5',
    color: '#660f0b',
  };

function Faq() {

  return (
    <div class= "mainStyles">
    <div style={faqStyles}>
      <div style={questionStyles}>
        <h2>FAQ'S</h2>
      </div>
    <div>
      <div style={questionStyles}>Q: Will I be completely naked during my massage?</div>
      <p style={answerStyles}>
      At the start of our session I will give you privacy to undress to your comfort level and lie down on the massage table beneath sheets and blankets.  Throughout the session I will uncover parts of your body one at a time as I work on them to maintain your modesty and comfort, and breast tissue and genitals will remain covered at all times. About 50% or my clients choose to leave underwear on, and 50% prefer to be completely undressed. If you are comfortable doing so, I recommend removing bras and chest binders to give me more access to the structures of your upper body, and to allow your body to relax, move, and breathe more freely, but if doing so feels uncomfortable or distressing you may choose to keep them on.
      </p>
      <div>
      <div style={questionStyles}>Q: Do you bill insurance?</div>
      <p style={answerStyles}>
      No I do not - but if you have an injury that has been diagnosed by a physician or physical therapist I can provide you with a superbill that you can submit to your insurance provider for reimbursement. It is then up to your insurance provider, and dependent on your specific plan, whether or not they will reimburse you for massage services. I recommend calling your insurance provider prior to booking with me to determine whether they will cover our session. I cannot guarantee that you will be reimbursed by insurance for my services.
      </p>
    </div>
    <div>
      <div style={questionStyles}>Q: How does your sliding scale payment work?</div>
      <p style={answerStyles}>
      I do not want my services to be a luxury accessible only to a few. If you cannot afford my set rate of $90/hr I am happy to offer a sliding scale, starting at $75/hr. Please&nbsp; 
      <a href="https://forms.gle/sqCaA1PbaFrff8Av5" target="_blank">contact me</a> 
      &nbsp;if you would like to take me up on this offer.
      </p>
    </div>
    <div>
      <div style={questionStyles}>Q: Am I supposed to tip for my massage?</div>
      <p style={answerStyles}>
      Different bodyworkers have different stances on this. I accept tips graciously as gifts, and view them as a way to offset the lower sliding scale payments I receive. I do not, however, expect tips, and whether or not you tip me does not impact my opinion of you, nor does it impact the quality of work I provide.
      </p>
    </div>
    <div>
      <div style={questionStyles}>Q: Am I too big to get a massage?</div>
      <p style={answerStyles}>
      “Bodywork for every body” means bodies of all sizes. My massage table is 32 inches wide, 73 inches long, and has a working load limit of 500 lbs. I have pillows, bolsters, and other props to help people get comfortable. If you are still concerned or have questions I encourage you to&nbsp;<a href="https://forms.gle/sqCaA1PbaFrff8Av5" target="_blank">contact me</a>.
      </p>
    </div>
    <div>
      <div style={questionStyles}>Q: Is your space wheelchair accessible?</div>
      <p style={answerStyles}>
      Unfortunately, my current space is not wheelchair accessible. To enter you will need to descend a flight of 11 stairs. There is a railing on the right side of the first 9 stairs, and no railing for the last 2 stairs. I apologize if this makes my space inaccessible to you. I can travel to your home for sessions, or I am happy to refer you to another massage therapist with accessible facilities. Thank you for your patience while I look for a more accessible facility.
      </p>
    </div>
    <div>
      <div style={questionStyles}>Q: How do in-home sessions work?</div>
      <p style={answerStyles}>
      I currently offer in-home services on Mondays in the Maine Lakes Region, which is where I live. After you book your first session you will receive an intake phone call from me to discuss what you are looking for, your symptoms and health history, and the expectations around the session. I bring my massage table and supplies, and you provide the space for me to set up and work. Rates are higher for these sessions to account for the added time and cost of travel.
      </p>
    </div>
    <div>
      <div style={questionStyles}>Q: Do you do sex work?</div>
      <p style={answerStyles}>
      Although I support and value sex workers and their contributions, as a Licensed Massage Therapist my services are strictly non-sexual; if you are looking for a sexual experience you will need to find it elsewhere. For my own protection, any sexual advances made by a client during a session result in termination of the session. Thank you in advance for helping us avoid this uncomfortable situation.
      </p>
    </div>
    </div>
  </div>
  </div>
  );
}

export default Faq;
