// CommentCarousel.js
import React from 'react';
import Slider from 'react-slick';
import Testimonials_1 from './Testimonials_1.png'
import Testimonials_2 from './Testimonials_2.png'
import Testimonials_3 from './Testimonials_3.png'
import Testimonials_4 from './Testimonials_4.png'
import Testimonials_5 from './Testimonials_5.png'
import Testimonials_6 from './Testimonials_6.png'
import Testimonials_7 from './Testimonials_7.png'

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CommentCarousel.css'; // Create this file for your styles

const CommentCarousel = ({ comments }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="comment-carousel-container">
      <Slider {...settings}>
      <div >
        <img src={Testimonials_1} alt="Image 1" style={{ width: '400px', height: '400px', marginLeft: '100px' }} />
      </div>
      <div>
        <img src={Testimonials_2} alt="Image 2" style={{ width: '400px', height: '400px', marginLeft: '100px' }}/>
      </div>
      <div>
        <img src={Testimonials_3} alt="Image 3" style={{ width: '400px', height: '400px', marginLeft: '100px' }}/>
      </div>
      <div>
        <img src={Testimonials_4} alt="Image 4" style={{ width: '400px', height: '400px', marginLeft: '100px' }} />
      </div>
      <div>
        <img src={Testimonials_5} alt="Image 5" style={{ width: '400px', height: '400px', marginLeft: '100px' }}/>
      </div>
      <div>
        <img src={Testimonials_6} alt="Image 6" style={{ width: '400px', height: '400px', marginLeft: '100px' }}/>
      </div>
      <div>
        <img src={Testimonials_7} alt="Image 7" style={{ width: '400px', height: '400px', marginLeft: '100px' }}/>
      </div>
      </Slider>
    </div>
  );
};

export default CommentCarousel;
