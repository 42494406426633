import { React, useEffect, useState } from "react";
import {
  Card,
  styled,
} from "@mui/material";
import seedrandom from 'seedrandom';
import Slider from 'react-slick'
import './HomePage.css';
import CommentCarousel from "../comment-carousel/CommentCarousel";
import beckford2 from './beckford_2.jpg';
import SW_1 from './SW_1.jpg';
import Logo from './Logo_yellow.png';


const StyledMainContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  display: "flex",
  //alignItems: "center",
  //position: "absolute",
  // top: "0",
  // bottom: "0",
  // left: "0",
  // right: "0",
}));

function Main() {
  const baseUrl = "https://homeostasis-massage.com/";

  const comments = [
    {
      text: "This was easily one of the best massage experiences I've ever had. Sage is careful, friendly, attentive, and very good at finding tension I didn't know I had. I walked out feeling refreshed and relaxed and wishing I'd gone for a longer appointment.",
      author: 'Evan',
    },
    {
      text: "I had a really great experience getting a massage from Sage. I was nervous because I'd never had a massage before and he explained everything he was doing before he started, really putting me at ease. I left feeling a lot more relaxed especially my always tight/painful shoulders. The space has a nice vibe too!",
      author: 'Hal',
    },
    {
      text: "Sage’s work is unlike anyone else’s! The sessions always give me just what I need—especially for help with neck and back pain. Confident, professional, and effective, I couldn’t recommend Sage enough!",
      author: 'Moriah',
    },
    {
      text: "Sage is a talented massage therapist. I have shoulder issues and he is helping me with that and I feel the tension melt away with his touch.",
      author: 'Gregory',
    },
    {
      text: "Parking is super easy and the space is warm and welcoming. Sage is an incredible massage therapist- his mission to provide bodywork to queer and trans bodies is such a necessary service. I will see him again!",
      author: 'Xenia',
    },
  ];
  

  return (
    // <StyledMainContainer>
      <div className="homepage-container">
        {/* Header Section */}
        <div className="header-section">
          {/* Logo */}
          <img src={Logo} alt="Logo" className="logo" />

          {/* Title */}
          <h1>Bodywork for Every Body</h1>

          {/* Description */}
          <p>
            In a world that shames and stigmatizes our bodies, I strive to help people find joy in and connection to their own unique self through individually tailored therapeutic massage sessions.
          </p>
        </div>

        {/* Image Section */}
        <div className="image-section">


          {/* Book Now Button */}
          <a href="https://www.massagebook.com/therapists/HomeostasisMassageandWellness/widget/services?src=external" className="book-now-button">Book Now</a>
          {/* <a href="https://www.massagebook.com/therapists/HomeostasisMassageandWellness/widget/services?src=external">
            <img src="http://www.massagebook.com/home/img/getbutton/button-booknow.png" alt="Book Now on MassageBook.com!" border="0"> </img>
            </a> */}
                      <img src={beckford2} alt="Image1" className="image" />
        </div>

        {/* Cream-colored background section */}
        <div className="cream-background">
          {/* Content */}
          <p>
            At Homeostasis Massage & Wellness, I provide compassionate, trauma-informed, and effective therapeutic bodywork to help you feel the relief and joy that you deserve. I incorporate deep tissue, myofascial, trigger point, and cupping techniques, and for individuals recovering from surgeries I offer manual lymphatic drainage and scar tissue massage. Each session is tailored to meet your individual needs, whether you are seeking pain relief, injury rehabilitation, scar mobilization, and/or emotional regulation.
          </p>

          <p>
            Massage is for everybody. As a transgender person, I have first-hand experience with discomfort and discrimination in health and wellness settings, and I am proud to instead provide care that is both affirming and effective to people of all identities and experiences. My services are not intended to be a luxury; if my set prices are inaccessible to you, 
            <a href="https://forms.gle/sqCaA1PbaFrff8Av5" target="_blank">&nbsp;please contact me to ask about my sliding scale.</a>
          </p>
        </div>

        {/* Another Image Section */}
        <div className="image-section">
          <img src={SW_1} alt="Image2" className="image" />
        </div>

        <h1>Testimonials</h1>

        {/* Sliding Component Section */}
          <div className="carousel">
            
            <CommentCarousel comments={comments} />
          </div>
      </div>
   // </StyledMainContainer>
  );
}

export default Main;
