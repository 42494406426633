import { React, useEffect, useState } from "react";
import { Card, CardContent, styled, useTheme, Typography } from "@mui/material";

const StyledMainContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "80%",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  margin: "4rem",
});
const StyledArchivesCard = styled(Card)(({ theme }) => ({
  margin: "2rem",

  borderRadius: "2rem",
  backgroundColor: theme.palette.backgroundSecondary.main,
  gap: "20px",
}));

//test
function Archives() {
  let date_1 = new Date("4/18/2023");
  let date_2 = new Date();
  let difference = date_2.getTime() - date_1.getTime();
  let day = Math.ceil(difference / (1000 * 3600 * 24));
  const baseUrl = "https://homeostatis-massage.com/";
  const imgExt = "photos/";
  const theme = useTheme();
  const [text, setText] = useState([]);
  const [img, setImg] = useState([]);

  //get Image aspect ratio
  const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  useEffect(() => {
    for (let i = 1; i < img.length; i++) {
      getMeta(`${baseUrl}${img[i].Key}`, (err, img) => {
        let aspectRatio = img.naturalWidth / img.naturalHeight;
        img[i].aspectRatio = aspectRatio;
      });
    }
  }, [img]);

  const DynamicCardContent = ({ numCards }) => {
    const cardContent = [];

    for (let i = 0; i < 121; i++) {
      cardContent.push(
        <StyledArchivesCard>
          <CardContent key={i}>
            {/* Render content for each card */}
            <h2>
              <Typography
                sx={{
                  fontFamily: "'Fira Sans Extra Condensed', sans-serif;",
                  fontSize: "2rem",
                }}
              >
                Day {i + 1}
              </Typography>
            </h2>
            <Typography sx={{ fontSize: "1.5rem" }}>{text[i]}</Typography>
            {img[i] && (
              <img
                alt=""
                src={img[i]}
                style={
                  img.aspectRatio > 0
                    ? {
                        width: (230 * img.aspectRatio).toString() + "px",
                        height: "230px",
                        margin: "2rem",
                      }
                    : {
                        width: "230px",
                        height: (230 * img.aspectRatio).toString() + "px",
                        margin: "2rem",
                      }
                }
              />
            )}
          </CardContent>
        </StyledArchivesCard>
      );
    }

    return cardContent;
  };

  return (
    <StyledMainContainer>

    </StyledMainContainer>
  );
}

export default Archives;
