import { React } from "react";
import { Typography, styled } from "@mui/material";
import instagramIcon from '../../instagram2.png';
import facebookIcon from '../../facebook2.png';
import './Footer.css'; // Import your CSS file
import Flag from './pride-flag.jpg';

//component styles
const StyledFooterContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.textSecondary.main + " !important",
  //display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "7rem",
  // maxHeight: "10rem",
  width: "100%",
  padding: "20px",

}));

const StyledFooterText = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    fontSize: ".6rem",
    maxWidth: "70%",
  },
}));

function Footer() {
  return (
    <StyledFooterContainer>
      <StyledFooterText>
      <div className="container">
      <div className="social-icons">
        <a href="https://www.instagram.com/sagewalkerlmt/" target="_blank" rel="noopener noreferrer">
          <img src={instagramIcon} alt="Instagram" style={{ backgroundColor: '#660f0b', padding: '20px', height: '50px', width: '50px' }} />
        </a>
        <a href="https://facebook.com/homeostasismassage" target="_blank" rel="noopener noreferrer">
          <img src={facebookIcon} alt="Facebook" style={{ backgroundColor: '#660f0b', padding: '20px', height: '50px', width: '50px' }} />
        </a>
      </div>
      <div className="contact-info">
        <p style={{color: "#2db4d2", 'font-family': 'Jost'}}>(207)-200-6163</p>
        <p style={{color: "#2db4d2", 'font-family': 'Jost'}}>869 Main St. Ste. 1000, Westbrook, ME 04092</p>
        <p style={{color: "#2db4d2", 'font-family': 'Jost' }}>sagewalker.lmt@gmail.com</p>
      </div>
      <div className="Flag">
      <img src={Flag} alt="Flag" className="flag-photo" />
    </div>
      </div>
      </StyledFooterText>
    </StyledFooterContainer>
  );
}

export default Footer;
