import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  typography: {
    fontFamily: "'Caveat', cursive",
  },
  palette: {
    background: {
      main: "#FFFFFF",
    },
    backgroundSecondary: {
      main: "#2db4d2",
    },
    buttonHover: {
      main: "#F7D3AD",
    },
    textMain: {
      main: "#2db4d2",
    },
    textSecondary: {
      main: "#660f0b",
    },
  },
});
