import { React } from "react";
import { styled, Typography } from "@mui/material";
import SarahImg from "../../assets/sarah.jpg";
import Footer from "../footer/Footer";
import './Contact.css';

const StyledMainContainer = styled("div")(({ theme }) => ({
 // display: "flex",
  //flexDirection: "row",
  //alignItems: "center",
  margin: "6rem 12rem",
  gap: "4rem",
  alignSelf: "center",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    margin: "2rem 2rem",
    width: "90%",
    gap: "2rem",
  },
}));

const StyledTextContainer = styled("div")({
  //display: "flex",
 // flexDirection: "column",
  gap: "2rem",
});

const StyledText = styled(Typography)({
  textIndent: "2rem",
  fontSize: "1.2rem",
});

const StyledImg = styled("img")(({ theme }) => ({
  width: "30rem",
  height: "30rem",
  borderRadius: "0.5rem",
  [theme.breakpoints.down("lg")]: {
    width: "16.5rem",
    height: "22rem",
  },
}));

function Contact() {
  return (
     <div class = "main-container">
      <div class = "i-frame-container">
    <iframe src="https://www.massagebook.com/therapists/HomeostasisMassageandWellness/widget/services" frameborder="0" width="100%" height="1000"></iframe>
    </div>
    <div class = "contact-button-container">
    <a href="https://forms.gle/sqCaA1PbaFrff8Av5" target="_blank" className="contact-button">Contact Me!</a>
    </div>
    </div>
  );
}

export default Contact;
